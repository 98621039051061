<template>
    <div class="upload-box">
        <div class="header flex-align-between">
            <span>我的报告</span>
            <div class="back flex-align" @click="goBack">
                <img src="@/assets/newReport/back.png" alt="">
                返回
            </div>
        </div>
        <div class="content-box">
            <div class="upload flex-align">
                <div class="box flex-align" @click="upload">
                    <i class="el-icon-upload"></i>
                    <span>上传作品</span>
                </div>
            </div>
            <el-table :data="tableData" style="width: 100%;" :header-cell-style="{ background: '#01B58C', color: '#fff' }">
                <el-table-column type="index" label="序号" align="center" width="150"></el-table-column>
                <el-table-column prop="recordName" label="上传记录名称" align="center"></el-table-column>
                <el-table-column label="上传结果" align="center" width="180">
                    <template slot-scope="scope">
                        上传完成 {{ scope.row.uploadNum }}/{{ scope.row.uploadNum }}
                    </template>
                </el-table-column>
                <el-table-column label="识别结果" align="center" width="180">
                    <template slot-scope="scope">
                        识别完成 {{ scope.row.identifyNum }}/{{ scope.row.uploadNum }}
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="上传时间" align="center" width="180"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <div class="handle-box flex-align">
                            <div class="btn btn-manage" @click="manmageWork(scope.row)">管理作品</div>
                            <!-- <el-tooltip class="item" :disabled="scope.row.reportStatus == 1 ? false : true" effect="light"
                                :content="content" placement="bottom"> -->
                            <div class="btn create" :class="{ 'ceate': scope.row.reportStatus == 1 }"
                                @click="createReport(scope.row.id)" @mouseenter="handleMouseEnter(scope.row)">
                                {{ scope.row.reportStatus == 0 ? '生成报告' : scope.row.reportStatus == 1 ? '生成中...' :
                                    '再次生成' }}
                            </div>
                            <!-- </el-tooltip> -->
                            <div class="btn view"
                                :class="{ 'gray': scope.row.reportStatus == 0 || scope.row.reportStatus == 1 }"
                                @click="viewReport(scope.row.reportStatus, scope.row)">查看报告</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pages flex-align-around">
                <el-pagination background layout="prev, pager, next,total,jumper" :page-size='limit' :total="total"
                    @current-change="pageChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            role: sessionStorage.getItem('role'),
            tableData: [],
            limit: 10,
            page: 1,
            total: 0,
            schoolName: '',
            schoolId: '',
            content: '',
            intervalId: null,
            isEnd: true,
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        pageChange(page) {
            console.log('page:', page);
            this.page = page;
            this.getRecordList()
        },
        prev() { },
        next() { },
        upload() {
            this.$router.push('/upload-list')
        },
        async getRecordList() {
            let data = {
                size: this.limit,
                current: this.page
            }
            let resData = await this.$Api.Upload.history(data);
            console.log('记录列表:', resData);
            this.tableData = resData.data.records;
            this.total = resData.data.total;

            this.isEnd = this.tableData.every(item => item.reportStatus == 2 || item.reportStatus == 0);
            if (this.isEnd) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            } else {
                if (this.intervalId) {
                    console.log('当前有定时器正在执行')
                } else {
                    this.getRecordListVal()
                }
            }
        },
        manmageWork(item) {
            let query = {
                id: item.id,
                examType: item.examType,
                examId: item.examId,
                classId: item.classId,
                termYear: item.termYear * 10 + item.termNum,
                grade: item.grade,
                status: item.reportStatus
            }
            this.$router.push({ path: '/upload-list', query })
        },
        async createReport(id) {
            let resData = await this.$Api.Upload.createReport(id);
            console.log('生成报告:', resData);
            if (resData.data.data.code == 301) {
                this.$message({
                    message: resData.data.data.msg,
                    type: 'warning'
                });
                return
            }
            this.getRecordList()
        },
        viewReport(reportStatus, row) {
            if (reportStatus !== 2) {
                this.$message({
                    message: '报告未生成，请稍后再试',
                    type: 'warning'
                });
                return
            }
            if (this.role == 11) {
                window.open(`/newreport?type=school&schoolId=${this.schoolId}&schoolName=${this.schoolName}&fromType=multiRole&showType=1&date=${row.termYear * 10 + row.termNum}&testType=${row.examType}&orgId=${row.grade}`, "_blank");
            } else {
                window.open(`/newreport?schoolId=${this.schoolId}&gradeId=${row.classId}&gradeValue=${row.className}&schoolName=${this.schoolName}&fromType=home&source=upload&termValue=${row.termYear * 10 + row.termNum}&reportValue=${row.examType}`, "_blank");
            }
        },
        async Personnel_information() {
            let resData = await this.$Api.Home.getUserInfo();
            this.UserInfo_data = resData.data;
            this.schoolName = this.UserInfo_data.schoolName;
            this.schoolId = this.UserInfo_data.schoolId;
        },
        async handleMouseEnter(item) {
            if (item.reportStatus == 1) {
                let data = {
                    id: item.id
                }
                let resData = await this.$Api.Upload.getRemainUserCount(data);
                console.log('剩余：', resData);
                if (resData.code == 200) {
                    this.content = `当前剩余${resData.data}人未生成`
                }
            }
        },
        // 循环查询作品列表
        getRecordListVal() {
            this.intervalId = setInterval(async () => {
                let data = {
                    size: this.limit,
                    current: this.page
                }
                let resData = await this.$Api.Upload.history(data);
                console.log('循环记录列表:', resData);
                this.tableData = resData.data.records;
                this.total = resData.data.total;
                this.isEnd = this.tableData.every(item => item.reportStatus == 2 || item.reportStatus == 0);
                if (this.isEnd) {
                    console.log('清除定时器')
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                }
            }, 5000)
        }
    },
    mounted() {
        this.getRecordList();
        this.Personnel_information()
    },
    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }
}
</script>
<style lang="less" scoped>
@import url("./less/upload.less");
</style>